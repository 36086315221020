/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HeadingSection, SubtitleSection } from '../../styles/Typography';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useIntl } from 'react-intl';

SwiperCore.use([Pagination]);

const Wrapper = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--gap-2) var(--padding);
  background: var(--blue-2);
  border-bottom: 4px solid var(--gold-2);
  flex-direction: column;
`;

const Container = styled.div`
  width: var(--layout-desktop-container);

  @media screen and (max-width: 1320px) {
    width: 100%;
  }
`;

const QuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  line-height: 1.4;
  align-items: center;
  padding-bottom: var(--gap-2);
`;

const QuotePre = styled.span`
  font-family: serif, 'Bookman', 'Palatino', 'Didot', 'Georgia',
    -apple-system-ui-serif, ui-serif, 'URW Bookman L', serif;
  font-size: 6rem;
  color: var(--gold-2);
  line-height: 0.8;
`;

const QuoteText = styled.p`
  font-family: serif, 'Bookman', 'Palatino', 'Didot', 'Georgia',
    -apple-system-ui-serif, ui-serif, 'URW Bookman L', serif;
  font-weight: 700;
  color: white;
  font-style: italic;
  font-size: var(--font-size-5);
  margin-bottom: var(--gap-4);

  @media screen and (max-width: 480px) {
    font-size: var(--font-size-3);
  }
`;

export const SectionTripleQuote = ({ quotesArray }) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <HeadingSection
        css={{ marginBottom: 'var(--gap-2)', textAlign: 'center' }}
      >
        {intl.formatMessage({ id: 'thoughtsTaste' })}
      </HeadingSection>
      <Container>
        <Swiper
          style={{
            '--swiper-pagination-color': 'white',
            '--swiper-pagination-bullet-inactive-color': 'white',
            '--swiper-pagination-bullet-size': '10px',
          }}
          // navigation
          grabCursor
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={20}
          breakpoints={{
            1201: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
        >
          {quotesArray.map(({ id, citazione, subtitle }) => (
            <SwiperSlide key={id}>
              <QuoteContainer>
                {/* <Quote> */}
                <QuotePre>‟</QuotePre>
                <QuoteText>{citazione}</QuoteText>
                <SubtitleSection
                  gold
                  css={{
                    fontSize: 'var(--font-size-micro)',
                    maxWidth: '70%',
                  }}
                >
                  {subtitle}
                </SubtitleSection>
                {/* </Quote> */}
              </QuoteContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Wrapper>
  );
};
