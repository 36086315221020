/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useReducer } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { breathingAnimLeft, breathingAnimRight, easeInOutSine } from '../../styles/animations';
import { Compass } from '../../vectors/Compass';
import { FullSwipableSlider, SwipableSlides } from '../sliders/SwipableSlides';
import { SeeAllChevron } from '../../ui/Buttons';
import { compassReducer } from '../../../functions/reducers';
import { CompassDrawing } from '../../vectors/CompassDrawing';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--padding) var(--padding) var(--gap-2) var(--padding);
  position: relative;
  flex-direction: column;
  background: var(--background-white);
  overflow-x: hidden;

  @media screen and (max-width: 600px) {
    overflow: hidden;
  }
`;

const HalfBg = styled.div`
  width: 100%;
  height: 70%;
  position: absolute;
  top: 0;
  background: var(--blue-2);
`;

const CompassContainer = styled.div`
  --width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(calc(var(--width) / 2) * -1);
`;

const CompassOutline = styled.div`
  --dot-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  --offset: 60px;
  --offsetWidth: calc(var(--width) + var(--offset));
  border-radius: var(--offsetWidth);
  height: var(--offsetWidth);
  width: var(--offsetWidth);
  background: var(--blue-2);
  border: 2px solid var(--background-white);
  position: relative;
`;

const CompassDot = styled.div`
  width: var(--dot-width);
  height: var(--dot-width);
  background: var(--gold-2);
  border-radius: var(--dot-width);
  position: absolute;
  bottom: calc(calc(var(--dot-width) / 2) * -1);
`;

const CompassHandler = styled.div`
  display: flex;
  z-index: 1;

  & svg {
    transition: all 600ms ${easeInOutSine};
    ${({ cssData: { intent, counter } }) => {
      if (intent === 'forward') {
        return css`
          transform: rotate(${90 * counter}deg);
        `;
      }
      return css`
        transform: rotate(${90 * counter}deg);
      `;
    }}
  }
`;

const CompassDotHandlerContainer = styled.div`
  display: grid;
  grid-template-columns: 40px auto 40px;
  position: absolute;
  align-items: center;
  bottom: calc(calc(26px + var(--dot-width)) * -1);
  justify-items: center;

  ${({ cssData: { counter } }) => {
    if (counter === 0) {
      return css`
        & #left_arrow {
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
    if (counter === 3) {
      return css`
        & #right_arrow {
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
  }}
`;

const CompassDotHandler = styled.div`
  font-size: var(--font-size-5);
  text-transform: uppercase;
  font-weight: 700;
  color: var(--gold-2);
`;

const arrowShared = css`
  z-index: 1;
  opacity: 0.5;
  background: none;
  appearance: none;
  padding: 0;
  width: 0;
  height: 0;
  border-style: solid;
`;

const CompassDotArrowLeft = styled.button`
  ${arrowShared}
  border-width: 12px 20px 12px 0;
  animation: ${breathingAnimLeft} 800ms ease infinite;
`;

const CompassDotArrowRight = styled.button`
  ${arrowShared}
  border-width: 12px 0 12px 20px;
  animation: ${breathingAnimRight} 800ms ease infinite;
`;

const CompassAlternativeContainerRight = styled.div`
  --offset: 40px;
  position: absolute;
  right: calc(calc(var(--dot-width) / 2) * -1);
`;

const CompassAlternativeContainerLeft = styled.div`
  --offset: 40px;
  position: absolute;
  left: calc(calc(var(--dot-width) / 2) * -1);
`;

const alternativeDotSharedCss = css`
  width: var(--dot-width);
  height: var(--dot-width);
  background: var(--gold-2);
  border-radius: var(--dot-width);
  transition: opacity 300ms ${easeInOutSine};
`;

const CompassAlternativeDotLeft = styled.div`
  ${alternativeDotSharedCss}
  left: calc(calc(var(--dot-width) / 2) * -1);
  ${({ cssData: { counter } }) => {
    if (counter === 0) {
      return css`
        opacity: 0;
      `;
    }
  }}
`;

const CompassAlternativeDotRight = styled.div`
  ${alternativeDotSharedCss}
  right: calc(calc(var(--dot-width) / 2) * -1);

  ${({ cssData: { counter } }) => {
    if (counter === 3) {
      return css`
        opacity: 0;
      `;
    }
  }};
`;

const CompassAlternativeTextLeft = styled.div`
  opacity: 0.5;
  font-size: var(--font-size-2);
  text-transform: uppercase;
  font-weight: 700;
  color: var(--gold-2);
  position: absolute;
  right: var(--offset);
  top: 0;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const CompassAlternativeTextRight = styled(CompassAlternativeTextLeft)`
  left: var(--offset);
  &&& {
    right: unset;
  }
`;

const SectionTitle = styled.h1`
  font-size: var(--font-size-6);
  color: var(--blue-2);

  @media screen and (max-width: 800px) {
    font-size: var(--font-size-5);
  }
`;

const SectionHeader = styled.div`
  z-index: 2;
  background: var(--blue-2);
  top: calc(var(--gap-5) * -1);

  width: 100%;
  position: relative;
  padding: var(--gap-3) var(--padding);
  justify-content: center;
  display: flex;

  @media screen and (max-width: 800px) {
    padding: var(--gap-4) var(--padding);
    width: 100vw;
  }
`;

const ButtonWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--padding) var(--padding) var(--padding);
  margin-top: calc(var(--gap-2) * -1);
  z-index: 2;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-top: calc(var(--gap-3) * -1);
  }
`;

const CompassDrawingContainer = styled.div`
  display: flex;
  transition: all 1000ms ${easeInOutSine};
  width: 100vw;
  pointer-events: none;

  @media screen and (min-width: 2561px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  ${({ cssData: { intent, counter } }) => {
    if (intent === 'forward') {
      return css`
        transform: translateX(${100 * counter * -1}vw);
      `;
    }
    return css`
      transform: translateX(${100 * counter * -1}vw);
    `;
  }}
`;

export const SectionCompass = ({ compassData }) => {
  const intl = useIntl();

  const [state, dispatch] = useReducer(compassReducer, compassData);
  const { data, counter, intent } = state;

  return (
    <>
      <Wrapper>
        <SectionHeader>
          <SectionTitle css={{ color: 'white' }}>
            {intl.formatMessage({ id: 'momentOfHappiness' })}
          </SectionTitle>
        </SectionHeader>
        <HalfBg />
        <CompassDrawingContainer cssData={{ intent, counter }}>
          <CompassDrawing />
        </CompassDrawingContainer>
        <CompassContainer>
          <CompassOutline>
            <CompassAlternativeContainerLeft>
              <CompassAlternativeDotLeft cssData={{ counter }} />
              <CompassAlternativeTextLeft
                role="button"
                onClick={() => dispatch({ type: 'back' })}
              >
                {counter === 0 ? ' ' : intl.formatMessage({ id: data[counter - 1].name })}
              </CompassAlternativeTextLeft>
            </CompassAlternativeContainerLeft>
            <CompassAlternativeContainerRight>
              <CompassAlternativeDotRight cssData={{ counter }} />
              <CompassAlternativeTextRight
                role="button"
                onClick={() => dispatch({ type: 'forward' })}
              >
                {counter === 3 ? ' ' : intl.formatMessage({ id: data[counter + 1].name })}
              </CompassAlternativeTextRight>
            </CompassAlternativeContainerRight>

            <CompassHandler cssData={{ intent, counter }}>
              <Compass
                id="compass_svg"
                widthHeight={{ width: '400px', height: '400px' }}
              />
            </CompassHandler>
            <CompassDot />
            <CompassDotHandlerContainer cssData={{ counter }}>
              <CompassDotArrowLeft
                aria-label={intl.formatMessage({ id: 'backward' })}
                id="left_arrow"
                onClick={() => dispatch({ type: 'back' })}
              />
              <CompassDotHandler>
                {intl.formatMessage({ id: data[counter].name })}
              </CompassDotHandler>
              <CompassDotArrowRight
                aria-label={intl.formatMessage({ id: 'forward' })}
                id="right_arrow"
                cssData={{ counter }}
                onClick={() => dispatch({ type: 'forward' })}
              />
            </CompassDotHandlerContainer>
          </CompassOutline>
        </CompassContainer>
        <FullSwipableSlider
          cssData={{ counter }}
          subSliders={
            <>
              <SwipableSlides
                $hasTopMargin
                productsArray={compassData.data[0]}
              />
              <SwipableSlides
                $hasTopMargin
                productsArray={compassData.data[1]}
              />
              <SwipableSlides
                $hasTopMargin
                productsArray={compassData.data[2]}
              />
              <SwipableSlides
                $hasTopMargin
                productsArray={compassData.data[3]}
              />
            </>
          }
        />
      </Wrapper>
      <ButtonWrapper>
        <SeeAllChevron />
      </ButtonWrapper>
    </>
  );
};
