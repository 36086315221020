import { graphql } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { HeroClip } from '../components/layout/sections/SectionHero';
import { PageWrapper } from '../components/layout/PageWrapper';
import { SectionCompass } from '../components/layout/sections/SectionCompass';
import { SectionSlider } from '../components/layout/sections/SectionSlider';
import { SectionImage } from '../components/layout/sections/SectionImage';
import { SectionTripleQuote } from '../components/layout/sections/SectionTripleQuote';

const Home = ({ data: {
  datoCmsHomepage: {
    productsBreakfast,
    productsLunch,
    productsSnack,
    productsDinner,
    prodottiSlider,
    titleSectionThree,
    immagineHero: { gatsbyImageData },
    heroImageMedium: { heroImageMediumData },
    backgroundSectionTwo: { gatsbyImageDataTwo },
    backgroundSectionFour: { gatsbyImageDataFour },
    ctaLinkSectionTwo,
    linkProductSectionFour,
    titleSectionFour,
    titleSectionTwo,
    subtitleSectionTwo,
    productTitleSectionFour,
    productDescriptionSectionFour,
    quotes,
  },
} }) => {
  const intl = useIntl();

  const compassData = {
    counter: 0,
    intent: null,
    data: [
      {
        name: 'breakfast',
        products: productsBreakfast,
      },
      {
        name: 'lunch',
        products: productsLunch,
      },
      {
        name: 'aperitif',
        products: productsSnack,
      },
      {
        name: 'dinner',
        products: productsDinner,
      },
    ],
  };
  const directedImages = withArtDirection(getImage(gatsbyImageData), [
    {
      media: '(max-width: 810px)',
      image: getImage(heroImageMediumData),
    },
  ]);

  return (
    <>
      <HeroClip
        scale
        maxHeight="850px"
        isHomePage
        clipHeight="800px"
        $minHeightMobile="700px"
        $gatsbyImageMaxHeight="800px"
        imageData={directedImages}
      />
      <SectionImage
        gold
        hasTopMargin
        hasMarginOffset
        imageData={gatsbyImageDataTwo}
        title={titleSectionTwo}
        subtitle={subtitleSectionTwo}
        hasButton
        $buttonGold
        goldHeading
        ctaLink={ctaLinkSectionTwo}
        buttonText={intl.formatMessage({ id: 'discoverMore' })}
        hasWave
        waveFill="white"
      />
      <SectionSlider
        sectionTitle={titleSectionThree}
        productsArray={prodottiSlider}
      />
      <SectionImage
        alignLeft
        gold
        forceOverlay
        alignTopVertical
        subHeading={productTitleSectionFour}
        imageData={gatsbyImageDataFour}
        title={titleSectionFour}
        subtitle={productDescriptionSectionFour}
        hasButton
        hasLinkToRecord
        recordId={linkProductSectionFour ? linkProductSectionFour.originalId : null}
        $hoverMobileColor="var(--gold-2)"
        buttonText={intl.formatMessage({ id: 'discoverMore' })}
      />
      <SectionTripleQuote quotesArray={quotes} />
      <SectionCompass compassData={compassData} />
    </>
);
};

const IndexPage = ({
  data,
  pageContext,
}) => {
  const { datoCmsHomepage: { seo } } = data;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <Home data={data} />
    </PageWrapper>
  );
};

export default IndexPage;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      immagineHero {
        gatsbyImageData
      }
      heroImageMedium {
        heroImageMediumData: gatsbyImageData
      }
      backgroundSectionTwo {
        gatsbyImageDataTwo: gatsbyImageData
      }
      titleSectionTwo
      subtitleSectionTwo
      ctaLinkSectionTwo
      linkProductSectionFour {
        ... on DatoCmsProdotti {
          originalId
        }
        ... on DatoCmsHeroProduct {
          originalId
        }
        ... on DatoCmsRicorrenze {
          originalId
        }
      }
      titleSectionThree
      prodottiSlider {
        ... on DatoCmsHeroProduct {
          originalId
          titolo
          homepageSliderQuote
          sliderDescription
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
        ... on DatoCmsProdotti {
          originalId
          titolo
          homepageSliderQuote
          sliderDescription
          heroProductRedirect
          productHeroReference {
            heroReferenceId: originalId
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
      }
      backgroundSectionFour {
        gatsbyImageDataFour: gatsbyImageData
      }
      quotes {
        id: originalId
        citazione
        subtitle
      }
      titleSectionFour
      productTitleSectionFour
      productDescriptionSectionFour
      productsBreakfast {
        ... on DatoCmsHeroProduct {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
        ... on DatoCmsProdotti {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          heroProductRedirect
          productHeroReference {
            originalId
            productHeroSlug: slug
            productCategory {
              productHeroCategorySlug: slug
            }
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
      }
      productsLunch {
        ... on DatoCmsHeroProduct {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
        ... on DatoCmsProdotti {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          heroProductRedirect
          productHeroReference {
            originalId
            productHeroSlug: slug
            productCategory {
              productHeroCategorySlug: slug
            }
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
      }
      productsSnack {
        ... on DatoCmsHeroProduct {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
        ... on DatoCmsProdotti {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          heroProductRedirect
          productHeroReference {
            originalId
            productHeroSlug: slug
            productCategory {
              productHeroCategorySlug: slug
            }
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
      }
      productsDinner {
        ... on DatoCmsHeroProduct {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
        ... on DatoCmsProdotti {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          heroProductRedirect
          productHeroReference {
            originalId
            productHeroSlug: slug
            productCategory {
              productHeroCategorySlug: slug
            }
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
      }
    }
  }
`;
