export const compassReducer = (state, action) => {
  let updatedState;
  const dataLength = state.data.length - 1;
  switch (action.type) {
    case 'back':
      if (state.counter === 0) {
        updatedState = { ...state, counter: 0 };
        break;
      }
      updatedState = {
        ...state,
        counter: state.counter - 1,
        intent: 'back',
      };
      break;
    case 'forward':
      if (state.counter === dataLength) {
        updatedState = { ...state, counter: dataLength };
        break;
      }
      updatedState = {
        ...state,
        counter: state.counter + 1,
        intent: 'forward',
      };
      break;
    default:
      throw new Error();
  }
  return updatedState;
};
