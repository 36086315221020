import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  HeadingSection,
  HeadingUppercase,
  SubtitleSection,
} from '../../styles/Typography';
import { ButtonNormal, NavigatorButtonNormal } from '../../ui/Buttons';
import { SvgWaveBottom } from '../../vectors/Waves';

const ImageSectionWrapper = styled.section`
  --margin-mobile: 160px;
  margin-top: ${({ hasMarginOffset }) => hasMarginOffset && '-160px'};
  position: relative;
  display: flex;
  align-items: center;
  z-index: ${({ forceOverlay }) => forceOverlay && '2'};
  justify-content: center;

  @media screen and (max-width: 960px) {
    margin-top: ${({ hasMarginOffset }) =>
      hasMarginOffset && 'calc(var(--margin-mobile) * -1)'};
    flex-direction: column;
  }
`;

const ImageSectionImage = styled(GatsbyImage)`
  width: 100%;
  min-height: 400px;
  max-height: 800px;
  z-index: -1;

  @media screen and (max-width: 1100px) {
    min-height: 400px;
  }

  @media screen and (max-width: 800px) {
    z-index: 1;
  }
`;

const ImageSectionContentBox = styled.div`
  width: 1240px;
  display: flex;
  margin-top: ${({ hasTopMargin }) => hasTopMargin && '80px'};
  justify-content: ${({ alignLeft }) =>
    alignLeft ? 'flex-start' : 'flex-end'};
  align-items: ${({ alignTopVertical }) =>
    alignTopVertical ? 'flex-start' : 'center'};
  padding: ${({ alignTopVertical }) =>
    alignTopVertical
      ? 'var(--gap-2) var(--padding) 0  var(--padding)'
      : '0 var(--padding)'};
  position: absolute;
  height: 100%;
  top: 0;

  @media screen and (max-width: 1240px) {
    width: 100%;
  }

  @media screen and (max-width: 960px) {
    margin-top: 0;
    padding: ${({ hasMarginOffset }) =>
      hasMarginOffset
        ? 'calc(var(--margin-mobile) + 60px) var(--gap-5) 60px var(--gap-5)'
        : 'var(--gap-2) var(--gap-5)'};
    background: var(--blue-2);
    position: relative;
    justify-content: flex-start;
  }
`;

const ImageSectionTextBox = styled.div`
  display: grid;
  row-gap: var(--gap-5);
`;

export const SectionImage = ({
  imageData,
  title,
  subtitle,
  gold,
  $buttonGold,
  goldHeading,
  hasButton,
  buttonText,
  hasWave,
  waveFill,
  hasMarginOffset,
  alignLeft,
  alignTopVertical,
  $hoverMobileColor,
  subHeading,
  forceOverlay,
  hasTopMargin,
  ctaLink,
  hasLinkToRecord,
  recordId,
}) => (
  <ImageSectionWrapper
    hasMarginOffset={hasMarginOffset}
    forceOverlay={forceOverlay}
  >
    <ImageSectionContentBox
      hasTopMargin={hasTopMargin}
      hasMarginOffset={hasMarginOffset}
      alignLeft={alignLeft}
      alignTopVertical={alignTopVertical}
    >
      <ImageSectionTextBox>
        <HeadingSection goldHeading={goldHeading}>{title}</HeadingSection>
        {subHeading && (
          <HeadingUppercase blueHeading>{subHeading}</HeadingUppercase>
        )}
        <SubtitleSection gold={gold}>{subtitle}</SubtitleSection>
        {hasButton && !hasLinkToRecord ? (
          <ButtonNormal
            $bolder
            $hoverMobileColor={$hoverMobileColor}
            $hasTopMargin
            $buttonGold={$buttonGold}
            buttonText={buttonText}
            buttonLink={ctaLink}
          />
        ) : (
          hasButton &&
          hasLinkToRecord && recordId && (
            <NavigatorButtonNormal
              $bolder
              $hoverMobileColor={$hoverMobileColor}
              $hasTopMargin
              $buttonGold={$buttonGold}
              buttonText={buttonText}
              recordId={recordId}
            />
          )
        )}
      </ImageSectionTextBox>
    </ImageSectionContentBox>
    <ImageSectionImage image={imageData} alt="" />
    {hasWave && <SvgWaveBottom waveFill={waveFill} />}
  </ImageSectionWrapper>
);

// Normal section

export const NormalSectionWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ hasSmallTopPadding }) =>
    hasSmallTopPadding
      ? 'var(--padding) var(--padding) var(--gap-2) var(--padding)'
      : 'var(--gap-2) var(--padding)'};
  position: relative;
  flex-direction: column;
`;
