/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIntl } from 'react-intl';
import { NormalSectionWrapper } from './SectionImage';
import { HeadingSection, HeadingSectionContainer } from '../../styles/Typography';
import { NavigatorButtonNormal } from '../../ui/Buttons';
import { ArrowSymbol } from '../../vectors/Symbols';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

SwiperCore.use([Pagination]);

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1100px;

  @media screen and (max-width: 1140px) {
    width: 100%;
  }
`;

const Slide = styled.div`
  padding-bottom: var(--gap-3);
  display: grid;
  row-gap: var(--gap-6);
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Image = styled(GatsbyImage)`
  max-width: 250px;
`;

const ProductTile = styled.h1`
  font-weight: 700;
  font-size: var(--font-size-2);
  color: var(--blue-2);
  line-height: 1.4;
  text-transform: uppercase;
`;

const ProductDescription = styled.p`
  font-weight: 400;
  font-size: var(--font-size-2);
  color: var(--blue-2);
  line-height: 1.3;
  margin-bottom: var(--gap-7);
`;

const SliderQuote = styled.h2`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-weight: 400;
  font-size: var(--font-size-5);
  line-height: 1.2;
  color: var(--gold-2);

  @media screen and (max-width: 800px) {
    font-size: var(--font-size-4);
  }
`;

const SwiperContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const SectionSlider = ({ productsArray, sectionTitle }) => {
  const intl = useIntl();

  return (
    <NormalSectionWrapper hasSmallTopPadding>
      {/* <SliderHalfBg /> */}
      <SliderContainer>
        <HeadingSectionContainer
          marginBottomMobile="var(--gap-5)"
          marginBottom="var(--gap-2)"
        >
          <HeadingSection blueHeading>{sectionTitle}</HeadingSection>
        </HeadingSectionContainer>
        <SwiperContainer>
          <Swiper
            style={{
              '--swiper-navigation-color': 'var(--gold-2)',
              '--swiper-pagination-color': 'var(--gold-2)',
            }}
            // navigation
            grabCursor
            pagination={{ clickable: true }}
            slidesPerView={1}
            spaceBetween={20}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 80,
              },
            }}
          >
            {productsArray.map(
              ({
                mainImage: { gatsbyImageData },
                titolo,
                originalId,
                sliderDescription,
                homepageSliderQuote,
                heroProductRedirect,
                productHeroReference,
              }) => (
                <SwiperSlide key={titolo}>
                  <Slide>
                    <SliderQuote>{homepageSliderQuote}</SliderQuote>
                    <ArrowSymbol />
                    <ImageWrapper>
                      <Image
                        loading="eager"
                        alt={titolo}
                        image={gatsbyImageData}
                      />
                    </ImageWrapper>
                    <ProductTile>{titolo}</ProductTile>
                    <ProductDescription>{sliderDescription}</ProductDescription>
                    {heroProductRedirect === true ? (
                      <NavigatorButtonNormal
                        blue
                        $bolder
                        buttonText={intl.formatMessage({ id: 'discover' })}
                        recordId={productHeroReference.heroReferenceId}
                      />
                    ) : (
                      <NavigatorButtonNormal
                        blue
                        $bolder
                        buttonText={intl.formatMessage({ id: 'discover' })}
                        recordId={originalId}
                      />
                    )}
                  </Slide>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </SwiperContainer>
      </SliderContainer>
    </NormalSectionWrapper>
  );
};
