/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { easeInOutSine } from '../../styles/animations';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigator } from '../../LanguageHelpers/Navigator';

SwiperCore.use([Pagination]);

const Overflow = styled.div`
  width: 1100px;
  overflow: hidden;

  @media screen and (max-width: 1060px) {
    width: 100%;
  }
`;

const Slider = styled.div`
  display: flex;
  width: 100%;
  left: 0;
  flex-direction: row;
  transition: all 600ms ${easeInOutSine};

  ${({ cssData: { counter } }) =>
    counter &&
    css`
      transform: translateX(calc(calc(100% * ${counter}) * -1));
    `}
`;

const Container = styled.div`
  --total-padding: calc(var(--padding) * 2);
  display: flex;
  width: 1100px;
  max-width: 1100px;
  align-items: center;
  margin-top: ${({ $hasTopMargin }) => $hasTopMargin && '100px'};
  position: relative;
  z-index: 3;

  @media screen and (max-width: 1060px) {
    width: 100%;
  }

  @media screen and (max-width: 640px) {
    margin-top: ${({ $hasTopMargin }) => $hasTopMargin && '60px'};
  }
`;

const ProductLink = styled(Navigator)`
  transition: transform 200ms ${easeInOutSine};
  display: flex;

  @media (hover: hover) {
    &:hover {
      transform: translateY(-5px);
    }
  }
`;

const Slide = styled.article`
  display: grid;
  row-gap: var(--gap-5);
  padding-bottom: var(--gap-3);
`;

const Image = styled(GatsbyImage)`
  /* Needed in order to avoid transition not triggering in iOS */
  & img {
    opacity: 1 !important;
    transition: none !important;
  }
`;

const Title = styled.h1`
  font-size: var(--font-size-2);
  color: var(--blue-2);
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
`;

export const SwipableSlides = ({ productsArray, $hasTopMargin }) => {
  return (
    <Container $hasTopMargin={$hasTopMargin}>
      <Swiper
        style={{
          '--swiper-navigation-color': 'var(--gold-2)',
          '--swiper-pagination-color': 'var(--gold-2)',
        }}
        // navigation
        grabCursor
        pagination={{ clickable: true }}
        slidesPerView={1}
        spaceBetween={40}
        breakpoints={{
          370: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 60,
          },
        }}
      >
        {productsArray.products.map(
          ({
            originalId,
            mainImage: { gatsbyImageData },
            titolo,
            heroProductRedirect,
            productHeroReference,
          }) => (
            <SwiperSlide key={originalId}>
              {heroProductRedirect === true ? (
                <ProductLink
                  recordId={productHeroReference.originalId}
                  key={originalId}
                >
                  <Slide>
                    <Image loading="eager" image={gatsbyImageData} alt="" />
                    <Title>{titolo}</Title>
                  </Slide>
                </ProductLink>
              ) : (
                <ProductLink recordId={originalId} key={originalId}>
                  <Slide>
                    <Image loading="eager" image={gatsbyImageData} alt="" />
                    <Title>{titolo}</Title>
                  </Slide>
                </ProductLink>
              )}
            </SwiperSlide>
          )
        )}
      </Swiper>
    </Container>
  );
};

export const FullSwipableSlider = ({ cssData, subSliders }) => (
  <Overflow>
    <Slider cssData={cssData}>{subSliders}</Slider>
  </Overflow>
);
