export const Compass = ({ widthHeight }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="140.446"
    height="140.446"
    viewBox="0 0 140.446 140.446"
    style={widthHeight}
  >
    <g transform="translate(-103.975 -0.945)">
      <path
        d="M328.125,75.038A62.226,62.226,0,0,1,265.9,137.263h0a62.226,62.226,0,1,1,62.228-62.227Z"
        transform="translate(-90.805 -5.712)"
        fill="#fff"
      />
      <path
        d="M368.194,79.3l3.111-.327a1.434,1.434,0,0,0,1.281-1.572l0-.029a53.184,53.184,0,0,0-45.7-45.615,1.433,1.433,0,0,0-1.594,1.251l0,.028-.327,3.11a1.436,1.436,0,0,0,1.254,1.569,47.178,47.178,0,0,1,40.413,40.336,1.437,1.437,0,0,0,1.568,1.251"
        transform="translate(-144.875 -14.153)"
        fill="#11284a"
      />
      <path
        d="M222.285,79.007l3.112.327a1.436,1.436,0,0,0,1.568-1.252,47.178,47.178,0,0,1,40.262-40.328,1.436,1.436,0,0,0,1.25-1.567l-.327-3.111a1.432,1.432,0,0,0-1.57-1.281l-.032,0a53.183,53.183,0,0,0-45.539,45.609,1.433,1.433,0,0,0,1.248,1.6l.027,0"
        transform="translate(-98.528 -14.172)"
        fill="#11284a"
      />
      <path
        d="M225.4,135.847l-3.111.327a1.432,1.432,0,0,0-1.278,1.571l0,.029a53.18,53.18,0,0,0,45.538,45.608,1.431,1.431,0,0,0,1.6-1.241c0-.011,0-.022,0-.033l.328-3.111a1.436,1.436,0,0,0-1.249-1.568A47.178,47.178,0,0,1,226.968,137.1a1.437,1.437,0,0,0-1.569-1.25"
        transform="translate(-98.531 -60.562)"
        fill="#11284a"
      />
      <path
        d="M371.305,136.2l-3.112-.327a1.434,1.434,0,0,0-1.567,1.251,47.181,47.181,0,0,1-40.415,40.335,1.436,1.436,0,0,0-1.254,1.568l.327,3.111a1.431,1.431,0,0,0,1.57,1.277l.027,0a53.182,53.182,0,0,0,45.7-45.613,1.433,1.433,0,0,0-1.249-1.6l-.029,0"
        transform="translate(-144.875 -60.574)"
        fill="#11284a"
      />
      <path
        d="M406.855,124.3a3.034,3.034,0,1,1-3.034-3.035,3.035,3.035,0,0,1,3.034,3.035"
        transform="translate(-178.685 -54.063)"
        fill="#b5a371"
      />
      <path
        d="M226.253,124.3a3.035,3.035,0,1,1-3.035-3.035,3.035,3.035,0,0,1,3.035,3.035"
        transform="translate(-98.166 -54.063)"
        fill="#b5a371"
      />
      <path
        d="M313.52,217.631a3.035,3.035,0,1,1,3.035-3.035h0a3.035,3.035,0,0,1-3.035,3.035Z"
        transform="translate(-138.425 -94.322)"
        fill="#b5a371"
      />
      <path
        d="M313.52,37.029a3.035,3.035,0,1,1,3.035-3.035h0a3.035,3.035,0,0,1-3.035,3.035Z"
        transform="translate(-138.425 -13.803)"
        fill="#b5a371"
      />
      <path
        d="M301,85.628l6.376-26.98a.489.489,0,0,1,.964.111V92.1a.489.489,0,0,1-.831.346l-6.379-6.357a.49.49,0,0,1-.131-.459"
        transform="translate(-134.191 -25.98)"
        fill="#b5a371"
      />
      <path
        d="M274.962,111.841l-26.98,6.376a.489.489,0,0,0,.111.964h33.34a.489.489,0,0,0,.346-.831l-6.358-6.379a.489.489,0,0,0-.458-.131"
        transform="translate(-110.392 -49.857)"
        fill="#b5a371"
      />
      <path
        d="M286.769,89.769l-1.012,6.064a.831.831,0,0,1-.682.682l-5.992,1a.831.831,0,0,1-.874-.436L270.633,82.5a.831.831,0,0,1,1.117-1.121l14.581,7.516a.831.831,0,0,1,.438.874"
        transform="translate(-120.617 -36.241)"
        fill="#11284a"
      />
      <path
        d="M286.769,150.593l-1.012-6.063a.831.831,0,0,0-.682-.682l-5.992-1a.831.831,0,0,0-.874.436l-7.575,14.58a.831.831,0,0,0,1.117,1.121l14.581-7.517a.831.831,0,0,0,.438-.874"
        transform="translate(-120.617 -63.682)"
        fill="#11284a"
      />
      <path
        d="M332.122,89.771l1.013,6.064a.831.831,0,0,0,.682.682l5.992,1a.831.831,0,0,0,.874-.436l7.575-14.58a.831.831,0,0,0-1.114-1.121L332.561,88.9a.832.832,0,0,0-.439.874"
        transform="translate(-148.067 -36.243)"
        fill="#11284a"
      />
      <path
        d="M332.122,150.593l1.013-6.063a.831.831,0,0,1,.682-.682l5.992-1a.831.831,0,0,1,.874.436l7.574,14.579a.831.831,0,0,1-1.114,1.121l-14.582-7.516a.831.831,0,0,1-.439-.874"
        transform="translate(-148.067 -63.682)"
        fill="#11284a"
      />
      <path
        d="M274.875,135.764l-26.98-6.377a.489.489,0,0,1,.111-.964h33.34a.489.489,0,0,1,.346.831l-6.358,6.38a.49.49,0,0,1-.458.13"
        transform="translate(-110.353 -57.256)"
        fill="#b5a371"
      />
      <path
        d="M301.088,140.115l6.376,26.98a.489.489,0,0,0,.964-.111v-33.34a.489.489,0,0,0-.831-.346l-6.379,6.358a.489.489,0,0,0-.131.458"
        transform="translate(-134.23 -59.367)"
        fill="#b5a371"
      />
      <path
        d="M325.022,85.628l-6.378-26.98a.488.488,0,0,0-.963.111V92.1a.489.489,0,0,0,.831.346l6.38-6.357a.49.49,0,0,0,.131-.459"
        transform="translate(-141.634 -25.98)"
        fill="#b5a371"
      />
      <path
        d="M329.373,111.841l26.979,6.376a.489.489,0,0,1-.111.964H322.9a.489.489,0,0,1-.346-.831l6.358-6.379a.491.491,0,0,1,.459-.131"
        transform="translate(-143.745 -49.857)"
        fill="#b5a371"
      />
      <path
        d="M329.461,135.764l26.979-6.377a.489.489,0,0,0-.111-.964H322.991a.489.489,0,0,0-.346.831l6.358,6.38a.492.492,0,0,0,.459.13"
        transform="translate(-143.784 -57.256)"
        fill="#b5a371"
      />
      <path
        d="M324.935,140.115l-6.377,26.98a.488.488,0,0,1-.964-.111v-33.34a.489.489,0,0,1,.831-.346l6.38,6.358a.489.489,0,0,1,.131.458"
        transform="translate(-141.595 -59.367)"
        fill="#b5a371"
      />
      <path
        d="M259.466,0a70.223,70.223,0,1,0,70.223,70.223A70.223,70.223,0,0,0,259.466,0m0,128.9a58.675,58.675,0,0,1-58.675-58.674v0a58.674,58.674,0,0,1,117.349,0h0A58.674,58.674,0,0,1,259.468,128.9h0"
        transform="translate(-85.267 0.945)"
        fill="#b5a371"
      />
    </g>
  </svg>
);
