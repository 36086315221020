export const ArrowSymbol = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="138.485"
    height="110.154"
    viewBox="0 0 138.485 110.154"
    style={{
      position: 'absolute',
      top: '2em',
      left: '-1.5em',
      transform: 'scale(0.6)',
    }}
  >
    <g transform="translate(-681.999 -2415.044)">
      <path
        d="M126.908,110.155a86.806,86.806,0,0,1-16.262-1.819l.641-2.932c.153.034,15.528,3.338,23.731.782a2.785,2.785,0,0,0,.366-.136c-1.321-2.339-12.786-8.116-23.195-12.267L113.3,91c.061.024,6.22,2.487,12.279,5.43,11.462,5.566,12.974,8.053,12.9,9.913-.028.722-.4,2.037-2.572,2.712a31.275,31.275,0,0,1-9,1.1"
        transform="translate(682 2415.043)"
        fill="var(--gold-2)"
      />
      <path
        d="M127.313,107.009l-.271-.056c-29.674-6.158-70.316-14.592-92.7-36.521C5.949,42.611.057.819,0,.4L2.974,0c.055.408,5.827,41.2,33.469,68.288C58.2,89.6,98.34,97.933,127.652,104.016l.269.055Z"
        transform="translate(682 2415.043)"
        fill="var(--gold-2)"
      />
    </g>
  </svg>
);

export const Triangle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="172"
    height="199"
    viewBox="0 0 172 199"
  >
    <path
      d="M99.5,0,199,172H0Z"
      transform="translate(172) rotate(90)"
      fill="#fff"
    />
  </svg>
);
